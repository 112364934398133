import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import * as queryString from 'query-string'

export const useDocumentTitle = title => {
  useEffect(() => {
    document.title = `Simple Cms | ${title}`
  }, [title])
}

export const useQueryParams = () => queryString.parse(useLocation().search)
