import { useNavigate } from 'react-router-dom'
import { useWorkspaces, AsyncStatus } from '@ossy/cms-client-react'
import { H1, H2, Switch, Button, Layout } from '@ossy-se/components-react'
import { AuthenticationGuard } from 'authentication'
import { useDocumentTitle } from 'hooks/index.js'

export const SelectWorkspacePage = () => {
  const navigate = useNavigate()
  const { status, workspaces } = useWorkspaces()

  useDocumentTitle('Select workspace')

  const navigateToWorkspace = id => {
    navigate(`/${id}/resources`)
  }

  const navigateToCreateWorkspace = () => {
    navigate('/create-workspace')
  }

  return (
    <AuthenticationGuard>
      <div
        className="bg-gradient-orange-blue flex-grow d-flex justify-center vh-100"
        style={{ paddingTop: '120px' }}
      >
        <div style={{ maxWidth: '900px', width: '90%' }}>

          <div className="d-flex justify-between align-center stack-m">
            <H1>My workspaces</H1>
            <Button
              variant="cta"
              onClick={navigateToCreateWorkspace}
              className="mobile:d-none">New workspace
            </Button>
          </div>

          <Switch on={status}>

            <Switch.Case match={[AsyncStatus.Error]}>
              <div>We could not load your workspaces, try reloading the page</div>
            </Switch.Case>

            <Switch.Case match={[AsyncStatus.Loading]}>
              <div>Loading...</div>
            </Switch.Case>

            <Switch.Case match={[AsyncStatus.Success]}>
              {workspaces.map(workspace => (
                <div
                  className="stack-m bg-color-foreground inset-stretch-m cursor-pointer shadow-m border-radius-s"
                  onClick={() => navigateToWorkspace(workspace.id)}
                >
                  <H2>{workspace.name.split('#')[0]}<span className="color-75"> {workspace.name.split('#')[1]}</span></H2>
                </div>
              ))}
            </Switch.Case>

          </Switch>

        </div>
      </div>
    </AuthenticationGuard>
  )
}
