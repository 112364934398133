import { useState, useEffect } from 'react'
import { pipe, keys, map, set, lensPath } from 'ramda'
import { useResource, useResourceTemplate, AsyncStatus } from '@ossy/cms-client-react'
import {
  Switch,
  Stack,
  Button,
  EmptyResult,
  H1,
  H2,
  InputTitle,
  useInputValue,
  DelayedRender
} from '@ossy-se/components-react'
import { EditFields } from './fieldFactory.js'

const ViewMode = {
  Read: 'READ',
  Edit: 'EDIT'
}

export const DocumentResource = ({
  resourceId,
  workspaceId,
  onClose: _onClose
}) => {
  const [viewMode, setViewMode] = useState(ViewMode.Read)
  const [pageStatus, setPageStatus] = useState(AsyncStatus.Loading)
  const [error] = useState()
  const [resourceContent, setResourceContent] = useState()
  const [resourceName, setResourceName] = useInputValue('')

  const {
    resource,
    status: resourceStatus,
    removeResource,
    updateResourceContent,
    renameResource
  } = useResource(resourceId)

  const template = useResourceTemplate(resource.type)

  const onCloseResource = () => {
    if (!_onClose) return
    setPageStatus(AsyncStatus.Loading)
    setViewMode(ViewMode.Read)
    setResourceName('')
    setResourceContent({})
    _onClose()
  }

  const onUpdateResource = () => {
    updateResourceContent(resourceContent)
      .then(() => setViewMode(ViewMode.Read))
      // TODO: proper error handling
      .catch(() => alert('Something went wrong!'))
      // .catch(error => {
      //   if (error.type === 'PROPERTIES_REQUIRED') setError('You need to change at least one field in the document to publish an update')
      // })
  }

  const onUpdateResourceContentField = () => {
    let value
    // TODO test all the different field types
    if (event.target.type === 'checkbox') {
      value = event.target.checked
    } else if (event.target.type === 'file') {
      value = event.target.files[0]
    } else {
      value = event.target.value
    }

    const path = event.target.type === 'radio'
      ? [event.target.name]
      : event.target.id.split('.')

    const lens = lensPath(path)

    setResourceContent(prevContent => set(lens, value, prevContent))
  }

  const onRenameResource = () => {
    if (resourceName === resource.name) return
    // TODO: loading/error handling
    renameResource(resourceName)
  }

  const onRemoveResource = () => {
    removeResource()
      .then(onCloseResource)
  }

  useEffect(() => {
    setResourceContent(resource.content)
    setResourceName(resource.name)
  }, [resource])

  useEffect(() => {

    if (!resourceId || !workspaceId) return setPageStatus(AsyncStatus.Error)

    const combinedStatuses = [resourceStatus]

    if (combinedStatuses.includes(AsyncStatus.Error)) {
      setPageStatus(AsyncStatus.Error)
    } else if (combinedStatuses.includes(AsyncStatus.Loading)) {
      setPageStatus(AsyncStatus.Loading)
    } else if (combinedStatuses.includes(AsyncStatus.NotInitialized)) {
      setPageStatus(AsyncStatus.Loading)
    } else if (combinedStatuses.every(status => status === AsyncStatus.Success)) {
      setPageStatus(AsyncStatus.Success)
    }

  }, [workspaceId, resourceStatus, resourceId])

  useEffect(() => {
    console.log('resource', resource)
  }, [resource])

  return (
    <Stack bordered>

      <Stack.Item fill>
        <Switch on={pageStatus}>

          <Switch.Case match={[AsyncStatus.Error]}>
            <EmptyResult
              heading="Not found"
              body="We can't find the resource you are looking for"
              style={{ padding: '40px 16px' }}
            />
          </Switch.Case>

          <Switch.Case match={[AsyncStatus.Loading]}>
            <DelayedRender>
              <span>loading...</span>
            </DelayedRender>
          </Switch.Case>

          <Switch.Case match={[AsyncStatus.Success]}>
            <Stack bordered>

              <Stack.Item>
                <Stack horizontal style={{ height: '54px' }}>

                  <Stack.Item fill style={{ padding: '16px 8px' }}>
                    <InputTitle
                      id="document-name"
                      type="text"
                      style={{ display: 'block', width: '100%' }}
                      value={resourceName}
                      onChange={setResourceName}
                      onBlur={onRenameResource}
                    />
                  </Stack.Item>

                  <Switch on={viewMode}>

                    <Switch.Case match={[ViewMode.Read]}>

                      <Button variant="command" onClick={() => setViewMode(ViewMode.Edit)}>
                        Edit
                      </Button>

                      <Button variant="command" onClick={onRemoveResource}>
                        Delete
                      </Button>

                    </Switch.Case>

                    <Switch.Case match={[ViewMode.Edit]}>
                      <Button variant="command" onClick={() => setViewMode(ViewMode.Read)}>
                        Cancel
                      </Button>

                      {
                        !!template.fields
                          ? <Button variant="command" onClick={onUpdateResource}>Update</Button>
                          : <Button disabled variant="disabled">Update</Button>
                      }

                    </Switch.Case>

                  </Switch>

                  {
                    !!_onClose && (
                      <Button variant="command" onClick={onCloseResource} >
                        Close
                      </Button>
                    )
                  }

                </Stack>

              </Stack.Item>

              {
                !!error && (
                  <Stack.Item>
                    {error}
                  </Stack.Item>
                )
              }

              <Stack.Item fill style={{ padding: '16px 8px' }}>

                { !!resource.data && !!resource.data.src && (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img src={resource.data.src} alt="test" style={{ width: 'auto', height: '400px', margin: '32px auto' }}/>
                  </div>
                )}

                <Switch on={viewMode}>

                  <Switch.Case match={[ViewMode.Read]}>
                    {
                      !!resourceContent && (template.fields || [])
                        .map(field => ({ name: field.name, value: resourceContent[field.name] }))
                        .map(field => (
                          <div key={field.name} className="stack-m sans-serif">
                            <span className="bold d-block stack-s">{field.name}</span>
                            {
                              typeof field.value === 'object'
                                ? pipe(keys, map(key => field.value[key] && <div>{key}</div>))(field.value)
                                : <span className="d-block">{field.value}</span>
                            }
                          </div>
                        ))
                    }
                  </Switch.Case>

                  <Switch.Case match={[ViewMode.Edit]}>
                    {
                      !!template.fields
                        ? <EditFields data={resourceContent} onChange={onUpdateResourceContentField} templateFields={template.fields} />
                        : <div className="inset-m"><H2>This documents template has been deleted and can therefore not be edited</H2></div>
                    }
                  </Switch.Case>

                </Switch>
              </Stack.Item>

              <Stack.Item>
                <div style={{ padding: '8px', fontFamily: 'sans-serif', fontSize: '12px', color: 'hsl(0, 0%, 40%)' }}>
                  Template name: {template.name}, Resource ID: {resource.id}
                </div>
              </Stack.Item>

            </Stack>

          </Switch.Case>

        </Switch>
      </Stack.Item>
    </Stack>
  )
}
