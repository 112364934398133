import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useResources } from '@ossy/cms-client-react'
import { H1, Modal, Button, Layout } from '@ossy-se/components-react'
import { useQueryParams } from 'hooks'

export const UploadImagePage = () => {
  const navigate = useNavigate()
  const { location } = useQueryParams()
  const { workspaceId } = useParams()
  const { uploadFile } = useResources()
  const [file, setFile] = useState()
  const [error, setError] = useState()

  const onUpload = () => {
    setError()
    uploadFile(location, file)
      .then(() => {
        setFile()
        navigate(`/${workspaceId}/resources${location}${directoryName}`)
      })
      .catch(err => {
        setError(err.message)
      })
  }

  return (
    <Layout variant="PerceivedMiddle" style={{ height: '100%' }}>
      <div
        slot="content"
        className="d-flex flex-column stack-xl"
        style={{ width: '100vw', maxWidth: '400px' }}
      >
        <H1 className="stack-l">Upload file</H1>
        <input
          type="file"
          className="stack-l"
          onChange={e => { setFile(e.target.files[0]) }}
        />
        <div>{ error }</div>
        <div className="d-flex justify-end">
          <Button
            id="upload"
            variant="cta"
            onClick={onUpload}
          >Upload
          </Button>
        </div>
      </div>
    </Layout>
  )
}
