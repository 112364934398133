import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { set, lensPath } from 'ramda'
import { useResources, useResourceTemplate } from '@ossy/cms-client-react'
import {
  InputTitle,
  Overlay,
  Button,
  Panel,
  Layout,
  useInputValue,
} from '@ossy-se/components-react'
import { CenterLayout } from 'layouts'
import { useQueryParams } from 'hooks'
import { EditFields } from './fieldFactory.js'

export const CreateDocumentPage = () => {
  const navigate = useNavigate()
  const { workspaceId } = useParams()
  const { templateId, location } = useQueryParams()
  const template = useResourceTemplate(templateId)
  const [documentData, setDocumentData] = useState({})
  const { createDocument } = useResources()
  const [documentName, setDocumentName] = useInputValue()
  const [error, setError] = useState()

  const onCancel = () => {
    setDocumentData({})
    navigate(`/${workspaceId}/resources${location}`)
  }

  const onFinish = () => {
    setDocumentData({})
    navigate(`/${workspaceId}/resources${location}`)
  }

  const onCreateDocument = () => {
    if (documentName === '' || documentName === undefined) {
      setError('Document name needs to be set')
      return ''
    }

    createDocument({
      type: templateId,
      location: location,
      name: documentName,
      content: documentData
    })
      .then(() => onFinish())
      .catch(error => { setError(error.message) })
  }

  const updateFieldData = event => {

    let value

    if (event.target.type === 'checkbox') {
      value = event.target.checked
    } else if (event.target.type === 'file') {
      value = event.target.files[0]
    } else {
      value = event.target.value
    }

    const path = event.target.type === 'radio'
      ? [event.target.name]
      : event.target.id.split('.')

    setDocumentData(set(lensPath(path), value, documentData))
  }

  return (
    <CenterLayout
      onBack={onCancel}
      error={error}
      actions={<Button variant="cta" onClick={onCreateDocument}>Create {template.name}</Button>}
      style={{ margin: '48px auto'}}
    >
      <Panel
        title={<InputTitle
          id="document-name"
          type="text"
          className="d-block"
          placeholder="Untitled document"
          value={documentName}
          onChange={setDocumentName}
        />}
        className="flex-fill"
      >
        <EditFields data={documentData} onChange={updateFieldData} templateFields={template.fields || []}/>
      </Panel>
    </CenterLayout>
  )
}
