import React from 'react'
import { H1, Button } from '@ossy-se/components-react'
import './HomePage.css'
import { useAuthentication, AuthenticationStatus } from '@ossy/cms-client-react'
import { Link } from 'react-router-dom'

const NotLoggedInButtons = () =>
  <>
    <Link to="/login">
      <Button variant="filled" className="inline-m">Login</Button>
    </Link>
    <Link to="/sign-up">
      <Button variant="cta">Sign up!</Button>
    </Link>
  </>

const LoggedInButtons = () =>
  <Link to="/select-workspace">
    <Button variant="cta">My workspaces</Button>
  </Link>

const TryForFreeButton = () =>
  <Link to="/sign-up">
    <Button variant="cta">
      Try it out for free!!
    </Button>
  </Link>

export const HomePage = () => {
  const { status } = useAuthentication()

  return (
    <>

      <header className="header">

        <div className="d-flex align-center">
          <span className="inline-xl bold">SimpleCms</span>
          {
            // <span className="menu">
            //   <Link to="/#">
            //     <ButtonLink>Documentation</ButtonLink>
            //   </Link>
            //   <Link to="/#">
            //     <ButtonLink>Pricing</ButtonLink>
            //   </Link>
            // </span>
          }
        </div>

        <div className="account">
          { status === AuthenticationStatus.Authenticated ? <LoggedInButtons/> : <NotLoggedInButtons/> }
        </div>

      </header>

      <main className="main">

        <div className="hero">
          <div className="hero-inner">
            <div className="stack-xxl">
              <H1 className="slogan stack-l">
                Manage your content.
                <br/>
                Hassle free!
              </H1>
              { status !== AuthenticationStatus.Authenticated && <TryForFreeButton /> }
            </div>
          </div>
        </div>

        {
          // <div className="features">
          //
          //   <div className="feature">
          //     <H1>User friendly</H1>
          //     <div className="inset-xl"></div>
          //   </div>
          //
          //   <div className="feature">
          //     <H1>Affordable</H1>
          //     <div className="inset-xl"></div>
          //   </div>
          //
          //   <div className="feature">
          //     <H1>Reliable</H1>
          //     <div className="inset-xl"></div>
          //   </div>
          //
          // </div>
          //
          // <footer className="inset-xl"></footer>
        }
      </main>

    </>

  )
}
