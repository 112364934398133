import { useState, useEffect } from 'react'
import {
  Switch,
  Stack,
  DropZone,
  Button,
  Dropdown,
  ContextMenu,
  Breadcrumbs,
  EmptyResult,
  DelayedRender,
  Icon,
  Layout
} from '@ossy-se/components-react'
import { WorkspaceModule } from 'workspaces'
import { useDocumentTitle } from 'hooks/index.js'
import {
  pipe,
  split,
  filter,
  identity,
  last,
  unless,
  startsWith,
  o,
  endsWith
} from 'ramda'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import {
  useResources,
  useWorkspace,
  useAuthentication,
  AsyncStatus
} from '@ossy/cms-client-react'
import './index.css'
import { ResourceFactory } from './ResourceFactory.js'
import '../workspaces/workspace.css'

const prependSlash = unless(startsWith('/'), path => `/${path}`)
const appendSlash = unless(endsWith('/'), path => `${path}/`)
const addSlashes = o(prependSlash, appendSlash)

const HomeButton = props => (
  <span style={{ display: 'flex', alignItems: 'center '}} {...props}>
    <Icon name="Home" />
  </span>
)

const Path = ({ path, className = '', ...restProps }) => (
  <span
    className={`sans-serif ${className}`}
    style={{
      fontSize: '16px',
      display: 'flex',
      alignItems: 'center'
    }}
    {...restProps}
  >
    {last(path.split('/'))}
  </span>
)

const ResourcesListStatus = {
  Error: 'Error',
  Loading: 'Loading',
  Empty: 'Empty',
  Success: 'Success'
}

export const ResourcesPage = () => {
  const navigate = useNavigate()
  const { workspaceId } = useParams()
  const location = useLocation()
  const { workspace } = useWorkspace()
  const [selectedResourceId, setSelectedResourceId] = useState()
  const [resourcesListStatus, setResourcesListStatus] = useState(ResourcesListStatus.Loading)
  const activePath = addSlashes(`${location.pathname.replace(`/${workspaceId}/resources`, '')}`)

  const {
    status: resourcesStatus,
    resources,
    removeResource,
    moveResource
  } = useResources(activePath)

  useDocumentTitle(workspace.name || 'loading workspace')

  const goTo = x => () => { navigate(`/${workspaceId}/resources${x}/`) }
  const goToRoot = () => { navigate(`/${workspaceId}/resources/`) }

  const onMoveResource = newLocation => resourceId => {
    moveResource(resourceId, newLocation)
  }

  const getActivePath = () => pipe(
    split('/'),
    filter(identity),
    x => x.reduce((acc, curr, i) => i === 0
      ? [`/${curr}`]
      : [...acc, `${acc[i - 1]}/${curr}`], []),
    filter(identity),
    paths => paths.map((path, i) => (
      i !== (paths.length - 1)
        ? (
          <DropZone onDrop={onMoveResource(`${path}/`)}>
            <Path key={path} path={path} onClick={goTo(path)} />
          </DropZone>
        )
        : <Path key={path} path={path} onClick={goTo(path)} />
    ))
  )(activePath)

  useEffect(() => {
    if (resourcesStatus === AsyncStatus.NotInitialized) {
      setResourcesListStatus(ResourcesListStatus.Loading)
    } else if (resourcesStatus === AsyncStatus.Loading) {
      setResourcesListStatus(ResourcesListStatus.Loading)
    } else if (resourcesStatus === AsyncStatus.Error) {
      setResourcesListStatus(ResourcesListStatus.Error)
    } else if (resourcesStatus === AsyncStatus.Success) {
      resources.length === 0
        ? setResourcesListStatus(ResourcesListStatus.Empty)
        : setResourcesListStatus(ResourcesListStatus.Success)
    }
  }, [resources, resourcesStatus])

  return (
    <WorkspaceModule>
      <div className="flex-fill" style={{ height: '100%' }}>

        <Stack horizontal bordered style={{ height: '100%' }}>

          <Stack.Item fill>
            <Stack bordered style={{ height: '100%' }}>

              <Breadcrumbs style={{ height: '54px', padding: '0 24px' }}>
                {
                  activePath === '/'
                    ? <HomeButton onClick={goToRoot}/>
                    : (
                      <DropZone onDrop={onMoveResource('/')}>
                        <HomeButton onClick={goToRoot}/>
                      </DropZone>
                    )
                }

                { activePath !== '/' && getActivePath() }
              </Breadcrumbs>

              <Switch on={resourcesListStatus}>

                <Switch.Case match={[ResourcesListStatus.Error]}>
                  <Stack.Item fill>
                    <Layout variant="VerticalTop" style={{ height: '100%' }}>
                      <EmptyResult
                        slot="content"
                        heading="Something went wrong"
                        body="We could not fetch the data to display"
                        style={{ width: '100vw', maxWidth: '350px' }}
                      />
                    </Layout>
                  </Stack.Item>
                </Switch.Case>

                <Switch.Case match={[ResourcesListStatus.Loading]}>
                  <DelayedRender>
                    {[1, 2, 3].map(n => (
                      <Stack.Item key={n}>
                        <div
                          className="inset-m cursor-pointer sans-serif d-flex align-center justify-between"
                        >
                          <div className="d-flex align-center">
                            <span
                              className="inline-m"
                              style={{
                                width: '20px',
                                height: '18px',
                                background: 'hsl(0, 0%, 90%)',
                                borderRadius: '16px'
                              }}
                            />
                            <span
                              style={{
                                width: '300px',
                                height: '18px',
                                background: 'hsl(0, 0%, 90%)',
                                borderRadius: '16px'
                              }}
                            />
                          </div>
                        </div>
                      </Stack.Item>
                    ))}
                  </DelayedRender>
                </Switch.Case>

                <Switch.Case match={[ResourcesListStatus.Empty]}>
                  <Stack.Item fill>
                    <Layout variant="VerticalTop" style={{ height: '100%' }}>
                      <EmptyResult
                        slot="content"
                        heading="Empty directory"
                        body="This directory is empty, you can add resources by pressing the 'Add' button"
                        style={{ width: '100vw', maxWidth: '350px' }}
                      />
                    </Layout>
                  </Stack.Item>
                </Switch.Case>

                <Switch.Case match={[ResourcesListStatus.Success]}>
                  {
                    resources.filter(resource => resource.type === 'directory')
                      .map(resource => (
                        <DropZone
                          key={resource.name}
                          onDrop={onMoveResource(`${resource.location}${resource.name}/`)}
                        >
                          <Stack.Item>
                            <div
                              className="hover:bg-96 inset-stretch-m cursor-pointer sans-serif d-flex align-center justify-between"
                              onClick={() => navigate(`/${workspaceId}/resources${activePath}${resource.name}/`)}
                            >
                              <div className="d-flex align-center">
                                <Icon name="Directory" style={{ fill: 'hsl(0, 0%, 80%)'}} className="inline-m"/>
                                <span>{resource.name}</span>
                              </div>
                              <Icon
                                name="Delete"
                                onClick={event => {
                                  event.stopPropagation()
                                  // removeResource(resource.id)
                                }}
                                style={{ fill: 'hsl(0, 0%, 80%)'}}
                                className="hover:fill-50 mobile:d-none"
                              />
                            </div>
                          </Stack.Item>
                        </DropZone>
                      ))
                  }

                  {
                    resources.filter(resource => resource.type !== 'directory')
                      .map(resource => (
                        <DropZone.Dragable dragData={resource.id} key={resource.id}>
                          <Stack.Item>
                            <div
                              className="hover:bg-96 inset-stretch-m cursor-pointer sans-serif d-flex align-center justify-between"
                              onClick={() => setSelectedResourceId(resource.id)}
                            >
                              <div className="d-flex align-center">
                                <Icon name="Document" style={{ fill: 'hsl(0, 0%, 80%)'}} className="inline-m"/>
                                <span style={{ marginRight: '32px' }}>{resource.name}</span>

                                <span style={{
                                  fontSize: '12px',
                                  color: 'hsl(0, 0%, 40%)'
                                }}>{resource.id}</span>
                              </div>
                              <Icon
                                name="Delete"
                                onClick={event => {
                                  event.stopPropagation()
                                  removeResource(resource.id)
                                }}
                                style={{ fill: 'hsl(0, 0%, 80%)'}}
                                className="hover:fill-50 mobile:d-none"
                              />
                            </div>
                          </Stack.Item>
                        </DropZone.Dragable>

                      ))
                  }
                </Switch.Case>

              </Switch>

            </Stack>
          </Stack.Item>

          {
            !!selectedResourceId && (
              <Stack.Item style={{ width: '50%', overflowY: 'auto' }}>
                <ResourceFactory
                  resourceId={selectedResourceId}
                  workspaceId={workspaceId}
                  onClose={() => setSelectedResourceId(undefined)}
                />
              </Stack.Item>
            )
          }

        </Stack>

      </div>
    </WorkspaceModule>
  )
}
