export const ossyTheme = {
  // button
  "button-default": 'transparent',
  "on-button-default": 'hsl(0, 0%, 50%)',
  "button-default-border": '2px solid hsla(0, 0%, 0%, 0)',
  "button-default--disabled": 'hsl(0, 0%, 94%)',
  "on-button-default--disabled": 'hsl(0, 0%, 50%)',
  "button-default-radius": '16px',

  "button-neutral": 'hsl(0, 0%, 90%)',
  "button-neutral--hover": 'hsl(0, 0%, 88%)',
  "on-button-neutral--hover": 'hsl(0, 0%, 15%)',

  "button-link": 'transparent',
  "button-link--hover": 'hsl(0, 0%, 94%)',
  "on-button-link--hover": 'hsl(0, 0%, 20%)',

  "button-cta": 'hsl(167, 89%, 43%)',
  "button-cta--hover": 'hsl(167, 89%, 43%)',
  "on-button-cta": 'hsl(0, 0%, 98%)',
  "on-button-cta--hover": 'hsl(0, 0%, 98%)',

  "button-danger": 'transparent',
  "button-danger--hover": 'hsl(0, 89%, 43%)',
  "on-button-danger--hover": 'hsl(0, 0%, 100%)',

  "button-command": 'transparent',
  "button-command--hover": 'hsl(199deg 90% 97%)',
  "on-button-command--hover": 'hsl(199deg 50% 50%)',
  "button-command-radius": '0',

  "button-command-cta": 'hsl(167, 89%, 43%)',
  "button-command-cta--hover": 'hsl(167, 89%, 43%)',
  "on-button-command-cta": 'hsl(0, 0%, 98%)',
  "on-button-command-cta--hover": 'hsl(0, 0%, 98%)',
  "button-command-cta-radius": '0',

  // icon
  "on-icon-default": 'hsl(0, 0%, 80%)',
  "on-icon-default--hover": 'hsl(0, 0%, 50%)'
}
