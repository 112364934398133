import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { AuthenticationGuard } from 'authentication'
import { WorkspaceDataLoader } from './WorkspaceDataLoader.js'
import { useWorkspaceConfig } from '@ossy/cms-client-react'
import { WorkspaceLayout } from './WorkspaceLayout.js'

export const WorkspaceModule = ({ children }) => {
  const { workspaceId } = useParams()
  const { setWorkspaceId } = useWorkspaceConfig()

  useEffect(() => {
    setWorkspaceId(workspaceId)
  }, [workspaceId])

  return (
    <WorkspaceDataLoader>
      <WorkspaceLayout>
        {children}
      </WorkspaceLayout>
    </WorkspaceDataLoader>
  )
}
