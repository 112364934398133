import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useWorkspaces } from '@ossy/cms-client-react'
import {
  Button,
  useInputValue,
  Layout,
  H1,
  Input
} from '@ossy-se/components-react'
import { AuthenticationGuard } from 'authentication'
import { useDocumentTitle } from 'hooks/index.js'


export const CreateWorkspacePage = () => {
  const navigate = useNavigate()
  const [error, setError] = useState()
  const [workspaceName, setWorkspaceName] = useInputValue()
  const { createWorkspace } = useWorkspaces()

  useDocumentTitle('Create workspace')

  const onCreateWorkspace = workspaceName => {
    createWorkspace(workspaceName)
      .then(workspace => { navigate(`/${workspace.id}/resources`) })
      .catch(err => { setError(err.message) })
  }

  return (
    <AuthenticationGuard roles={['DEVELOPER']} >
      <Layout variant="PerceivedMiddle" className="bg-gradient-orange-blue vh-100">
        <div
          slot="content"
          className="d-flex flex-column"
          style={{ width: '100vw', maxWidth: '372px' }}
        >

          <H1 className="stack-m">Create a new workspace</H1>

          <Input
            id="workspaceName"
            className={ error ? 'stack-s' : 'stack-m' }
            type="text"
            placeholder="Workspace name"
            required
            onChange={setWorkspaceName}
          />

          <div className={error ? 'stack-m' : ''}>{ error }</div>

          <div className="d-flex justify-end">

            <Button
              variant="link"
              id="cancel"
              onClick={() => navigate(-1)}>Cancel
            </Button>

            <Button
              id="createWorkspace"
              variant="cta"
              onClick={() => onCreateWorkspace(workspaceName)}>Create
            </Button>

          </div>

        </div>
      </Layout>
    </AuthenticationGuard>
  )
}
