import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { H1, H2, Switch, Button } from '@ossy-se/components-react'
import { useWorkspace, AsyncStatus } from '@ossy/cms-client-react'
import { WorkspaceModule } from 'workspaces'
import { CenterLayout } from 'layouts/index.js'
import './manageTemplate.css'

export const TemplatesPage = () => {
  const navigate = useNavigate()
  const { workspace } = useWorkspace()

  return (
    <WorkspaceModule>
      <CenterLayout>
      
        <div className="d-flex justify-between align-center stack-xl">
          <H1>Templates</H1>
          <Button
            variant="cta"
            onClick={() => navigate(`/${workspaceId}/create-template`)}
          >New tempate
          </Button>
        </div>

        <div className="manageTemplate__grid">
          {
            workspace.resourceTemplates.map(template => (
              <div
                key={template.id}
                className="bg-color-foreground inset-m cursor-pointer shadow-m hover:shadow-focus-m border-radius-s d-flex justify-start align-end"
                onClick={() => navigate(`/${workspaceId}/edit-template/${template.id}`)}
              >
                <H2>{ template.name }</H2>
              </div>
            ))
          }
        </div>

      </CenterLayout>
    </WorkspaceModule>

  )
}
