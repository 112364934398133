import React from 'react'
import {
  cond,
  equals,
  mergeLeft,
  prop,
  set,
  lensPath,
  pipe
} from 'ramda'
import {
  Input2,
  Textarea as TextareaInput,
  Select as SelectInput
} from '@ossy-se/components-react'

const Text = ({ label, className = '', style = {}, ...props }) =>
  <label className="d-block stack-m bold sans-serif" key={label}>
    <span className="d-block stack-s">{label}</span>
    <Input2
      {...props}
      id={label}
      type="text"
      className={`d-block ${className}`}
      style={mergeLeft(style, { minWidth: '50%'})}
    />
  </label>

const Textarea = ({ label, className = '', style = {}, ...props }) =>
  <label className="d-block stack-m bold sans-serif" key={label}>
    <span className="d-block stack-s">{label}</span>
    <TextareaInput
      {...props}
      id={label}
      className={`d-block ${className}`}
      style={mergeLeft(style, { minWidth: '100%', minHeight: '125px'})}
      rows="2"
      cols="50"
    />
  </label>

const Select = ({ label, options, className = '', style = {}, ...props }) =>
  <label className="d-block stack-m bold sans-serif" key={label}>
    <span className="d-block stack-s">{label}</span>
    <SelectInput
      {...props}
      id={label}
      className={`d-block ${className}`}
      style={mergeLeft(style, { minWidth: '50%'})}
    >
      <option value={undefined}></option>
      {options.map(option => <option key={option} value={option}>{option}</option>)}
    </SelectInput>
  </label>

const Radio = ({ label, value, options, onChange }) =>
  <div className="stack-m" onChange={onChange} key={label}>
    <div className="bold sans-serif stack-s">{label}</div>
    { options.map(option => (
      <label className="d-block stack-s sans-serif" key={`${label}-${option}`}>
        <input
          id={`${label}.${option}`}
          name={label}
          type="radio"
          className="inline-s"
          value={option}
          checked={value === option}
        />
        <span>{option}</span>
      </label>
    )) }
  </div>

const Checkbox = ({ label, value, options, onChange }) =>
  <div className="stack-m" key={label}>
    <div className="bold sans-serif stack-s">{label}</div>
    { options.map(option => (
      <label className="d-block sans-serif stack-s" key={`${label}-${option}`}>
        <input
          id={`${label}.${option}`}
          name={label}
          type="checkbox"
          className="inline-s"
          value={option}
          checked={!!prop(option, value)}
          onChange={onChange}
        />
        <span>{option}</span>
      </label>
    )) }
  </div>

const Image = ({ label, className = '', style = {}, ...props }) =>
  <label className="d-block stack-m bold sans-serif" key={label}>
    <span className="d-block stack-s">{label}</span>
    <Input2
      {...props}
      id={label}
      type="file"
      className={`d-block ${className}`}
      style={mergeLeft(style, { minWidth: '50%'})}
    />
  </label>

const setValue = data => field => set(lensPath(['value']), data[field.name], field)
const setLabel = field => set(lensPath(['label']), field.name, field)
const setOnChange = fn => field => set(lensPath(['onChange']), fn, field)
const ofType = type => field => equals(type, field.type)
const render = Component => props => <Component {...props}/>

export const EditFields = ({ data, onChange, templateFields }) =>
  templateFields.map(pipe(
    setValue(data),
    setLabel,
    setOnChange(onChange),
    cond([
      [ofType('text'), render(Text)],
      [ofType('textarea'), render(Textarea)],
      [ofType('select'), render(Select)],
      [ofType('radio'), render(Radio)],
      [ofType('checkbox'), render(Checkbox)],
      [ofType('image'), render(Image)]
    ])
  ))
