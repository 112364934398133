import { DocumentResource } from './DocumentResource.js'
// import { ImageResource } from './ImageResource'

export const ResourceFactory = ({
  resourceId,
  workspaceId,
  onClose: _onClose
}) => {
  const Component = DocumentResource

  return (
    <Component
      resourceId={resourceId}
      workspaceId={workspaceId}
      onClose={_onClose}
    />
  )
}
