import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { H1, Button, Panel } from '@ossy-se/components-react'
import { WorkspaceModule } from 'workspaces'
import { CenterLayout } from 'layouts/index.js'
import { without, prop } from 'ramda'
import { EditTemplateFields } from './editFields.js'
import { useResourceTemplate } from '@ossy/cms-client-react'
import './manageTemplate.css'

export const EditTemplatePage = () => {
  const navigate = useNavigate()
  const { workspaceId, templateId } = useParams()
  const [error, setError] = useState()
  const [fields, setFields] = useState([])
  const { template, removeTemplate, updateTemplateFields } = useResourceTemplate(templateId)

  useEffect(() => {
    const templateFields = prop('fields', template)
    if (templateFields) setFields(templateFields)
  }, [template])

  const addField = field => {
    setFields([...fields, field])
  }

  const removeField = field => {
    setFields(without([field], fields))
  }

  const toTemplatesView = () => {
    navigate(`/${workspaceId}/manage-templates`)
  }

  const deleteTemplate = () => {
    setError()
    removeTemplate(template.id)
      .then(toTemplatesView)
      .catch(err => {
        setError(err.message)
      })
  }

  const onUpdateTemplate = () => {
    updateTemplateFields(fields)
      .then(toTemplatesView)
      .catch(err => {
        if (err.type === 'DOCUMENT_TYPE_IS_MISSING_A_VALID_FIELD') {
          setError('Template must have at least one field')
          return
        }
        setError(err.message)
      })
  }

  return (
    <WorkspaceModule>
      <CenterLayout
        onBack={toTemplatesView}
        actions={<>
          <Button variant="danger" className="inline-m" onClick={deleteTemplate}>Delete</Button>
          <Button onClick={onUpdateTemplate}>Update</Button>
        </>}
      >
        <Panel title={<H1>{template.name}</H1>}>
          <EditTemplateFields fields={fields} onAddField={addField} onRemoveField={removeField} />
          {error}
        </Panel>
      </CenterLayout>
    </WorkspaceModule>
  )
}
