import React, { useState, useRef } from 'react'
import {
  useInputValue,
  Button,
  Switch,
  Layout,
  H1,
  Input
} from '@ossy-se/components-react'
import { useDocumentTitle } from 'hooks/index.js'
import { useNavigate } from 'react-router-dom'
import { useAuthentication } from '@ossy/cms-client-react'

const FlowStage = {
  Input: 'Input',
  SigningIn: 'SigningIn',
  Success: 'Success'
}

export const LoginPage = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useInputValue()
  const formRef = useRef()
  const { signIn } = useAuthentication()
  const [flowStage, setFlowStage] = useState(FlowStage.Input)

  useDocumentTitle('Sign in')

  const login = event => {
    event.preventDefault()
    setFlowStage(FlowStage.SigningIn)
    formRef.current.reportValidity() && signIn(email)
      .then(() => setFlowStage(FlowStage.Success))
      .catch(() => setFlowStage(FlowStage.Success)) // Don't show the user if an email doesn't exist
  }

  return (
    <Layout variant="PerceivedMiddle" className="bg-gradient-orange-blue vh-100">
      <div slot="content" style={{ width: '100vw', maxWidth: '372px' }}>
        <Switch on={flowStage}>

          <Switch.Case match={[FlowStage.Input]}>
            <H1 className="stack-m">Login</H1>
            <form
              ref={formRef}
              onSubmit={login}
              className="d-flex flex-column"
              style={{ width: '100%'}}
            >
              <Input
                id="loginemailField"
                className="stack-m"
                type="email"
                required
                placeholder="Email address"
                onChange={setEmail}
              />

              <div className="d-flex justify-end">

                <Button
                  variant="link"
                  id="cancelButton"
                  onClick={() => navigate(-1)}>Cancel
                </Button>

                <Button
                  id="registerButton"
                  variant="cta"
                  onClick={login}>Log In
                </Button>

              </div>
            </form>
          </Switch.Case>

          <Switch.Case match={[FlowStage.SigningIn]}>
            <H1>Signing in...</H1>
          </Switch.Case>

          <Switch.Case match={[FlowStage.Success]}>
            <H1>Success</H1>
            <p>
              We have sent a magic login link to the email provided, if the account exist
            </p>
          </Switch.Case>

        </Switch>

      </div>
    </Layout>
  )
}
