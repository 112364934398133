import { useLocation, useNavigate, Routes, Route } from 'react-router-dom'
import { useAuthentication, useWorkspace, useResourceTemplates } from '@ossy/cms-client-react'
import { Button, Stack, H1, Dropdown, ContextMenu, Icon  } from '@ossy-se/components-react'
import { useQueryParams } from 'hooks'
import { useDocumentTitle } from 'hooks/index.js'
import { MobileMenu } from './mobileMenu/index.js'

import './workspace.css'

export const WorkspaceLayout = ({ children }) => {
  const navigate = useNavigate()
  const { signOff } = useAuthentication()
  const { workspace } = useWorkspace()
  const { location } = useQueryParams()

  let activePath = location || '/'

  if (!activePath.endsWith('/')) {
    activePath += '/'
  }

  useDocumentTitle(workspace.name || 'loading workspace')

  const navigateToResources = () => {
    navigate(`/${workspace.id}/resources/`)
  }

  return (
    <div className="workspace">

      <Stack as="header" horizontal className="workspace__header" style={{ width: '100%' }}>

        <Stack.Item style={{ display: 'flex', alignItems: 'center' }}>
          <Dropdown className="mobile:d-none" trigger={<Button variant="cta" style={{ margin: 'auto 24px'}}>Add</Button>}>
            <ContextMenu>

              <ContextMenu.Item onClick={() => navigate(`/${workspace.id}/create-directory?location=${activePath}`)}>
                Directory
              </ContextMenu.Item>

              { !!workspace.resourceTemplates.length && <ContextMenu.Separator /> }

              <ContextMenu.Item onClick={() => navigate(`/${workspace.id}/upload-image?location=${activePath}`)}>
                Image
              </ContextMenu.Item>

              { workspace.resourceTemplates.map(({ name, id }) => (
                <ContextMenu.Item onClick={() => navigate(`/${workspace.id}/create-document?location=${activePath}&templateId=${id}`)} key={id}>
                  {name}
                </ContextMenu.Item>
              ))}
            </ContextMenu>
          </Dropdown>
        </Stack.Item>

        <H1 style={{ margin: 'auto 0', align: 'center' }}>{workspace.name}</H1>

        <Stack.Item fill>
        </Stack.Item>

        <Button variant="command" onClick={() => navigate('/select-workspace')} >
          My Workspaces
        </Button>

        <Button variant="command">
          Profile
        </Button>

        <Button variant="command" onClick={signOff}>
          Log out
        </Button>

      </Stack>

      <div className="workspace__nav-mobile">
        <MobileMenu>
          <MobileMenu.Item icon={<Icon name="Directory"/>} onClick={navigateToResources} />
          <MobileMenu.Item icon={<Icon name="InsertPhoto"/>} onClick={navigateToResources} />
        </MobileMenu>
      </div>

      <main className="workspace__main">
        {children}
      </main>

    </div>
  )
}
