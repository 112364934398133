import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuthentication } from '@ossy/cms-client-react'
import { H1, Switch, Layout } from '@ossy-se/components-react'
import { useDocumentTitle, useQueryParams } from 'hooks/index.js'

const FlowStage = {
  SigningIn: 'SigningIn',
  Success: 'Success',
  Error: 'Error'
}

export const VerifySignInPage = () => {
  const navigate = useNavigate()
  const { token } = useQueryParams()
  const { verifySignIn } = useAuthentication()
  const [flowStage, setFlowStage] = useState(FlowStage.SigningIn)

  useDocumentTitle('Signing in...')

  useEffect(() => {
    if (!token) return

    let didCancel = false

    setFlowStage(FlowStage.SigningIn)

    verifySignIn(token)
      .then(() => {
        if (didCancel) return
        setFlowStage(FlowStage.Success)
      })
      .then(() => navigate('/select-workspace'))
      .catch(() => {
        if (didCancel) return
        setFlowStage(FlowStage.Error)
      })

    return () => {
      didCancel = true
    }

  }, [token])

  return (
    <Layout variant="PerceivedMiddle" className="bg-gradient-orange-blue vh-100">
      <div style={{ width: '100vw', maxWidth: '372px' }}>
        <Switch on={flowStage}>

          <Switch.Case match={[FlowStage.SigningIn]}>
            <H1>Signing in...</H1>
          </Switch.Case>

          <Switch.Case match={[FlowStage.Success]}>
            <H1>Success, redirecting...</H1>
          </Switch.Case>

          <Switch.Case match={[FlowStage.Error]}>
            <H1>Could not sign in, try again later</H1>
          </Switch.Case>

        </Switch>
      </div>
    </Layout>
  )
}
