import React from 'react'
import './index.css'
import { Alert, Button, Layout } from '@ossy-se/components-react'

export const CenterLayout = ({
  children,
  error,
  onBack,
  actions,
  style: styles,
  className = '',
  ...props
}) => (
  <Layout variant="VerticalTop" style={{ height: '100%', ...styles }} {...props}>
    <div slot="content" style={{ width: '100vw', maxWidth: '900px' }}>
      {
        (!!onBack || !!actions) && (
          <div className="layout-center__actions bg-color-foreground border-radius-s shadow-m inset-s">
            <div>{!!onBack && <Button variant="link" onClick={onBack}>Back</Button>}</div>
            <div>{actions}</div>
          </div>
        )
      }
      <div className="layout-center__content">
        { error && <Alert className="stack-m" style={{ marginTop: '-71px' }}>{error}</Alert>}
        {children}
      </div>
    </div>
  </Layout>
)
