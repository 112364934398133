import React from 'react'
import { Switch, Icon  } from '@ossy-se/components-react'
import { useWorkspace, AsyncStatus} from '@ossy/cms-client-react'
import './workspace.css'

export const WorkspaceDataLoader = ({ children }) => {
  const { status: pageStatus } = useWorkspace()

  return (
    <Switch on={pageStatus}>

      <Switch.Case match={[AsyncStatus.Error]}>
        <div className="flex-fill h-50 d-flex justify-center align-center fill-50">
          We could not load your workspace, try reloading the page.
        </div>
      </Switch.Case>

      <Switch.Case match={[AsyncStatus.Loading]}>
        <div className="flex-fill h-50 d-flex justify-center align-center fill-50">
          <Icon
            name="Cached"
            style={{ width: '64px', height: '64px' }}
            className="rotate"
          />
        </div>
      </Switch.Case>

      <Switch.Case match={[AsyncStatus.Success]}>
        {children}
      </Switch.Case>

    </Switch>
  )
}
