import React from 'react'
import { RouterProvider, createBrowserRouter, redirect } from 'react-router-dom'
import { WorkspaceProvider } from '@ossy/cms-client-react'
import { Theme } from '@ossy-se/components-react'
import { MissingPage } from 'errors'
import { HomePage } from 'home'
import { DocumentationPage } from 'documentation'
import { SelectWorkspacePage, CreateWorkspacePage, WorkspaceModule, } from 'workspaces'
import { LoginPage, SignUpPage, VerifySignInPage, VerifyNoncePage } from 'authentication'
import { TemplatesPage, EditTemplatePage } from 'templates'
import { ResourcesPage, CreateDirectoryPage, CreateDocumentPage, UploadImagePage } from 'resources'
import { Analytics } from 'integrations/google/index.js'
import { config } from 'config.js'
import { ossyTheme } from 'themes/index.js'
import './app.css'

const router = createBrowserRouter([
  {
    path: '/',
    element: <HomePage/>
  },
  {
    path: "/sign-up",
    element: <SignUpPage/>,
  },
  {
    path: "/login",
    element: <LoginPage/>,
  },
  {
    path: "/verify-sign-in",
    element: <VerifySignInPage/>,
  },
  {
    path: "/documentation",
    element: <DocumentationPage/>,
  },
  {
    path: "/404",
    element: <MissingPage/>,
  },
  {
    path: "/create-workspace",
    element: <CreateWorkspacePage/>,
  },
  {
    path: "/select-workspace",
    element: <SelectWorkspacePage/>,
  },
  {
    path: '/:workspaceId',
    loader: () => redirect('resources')
  },
  {
    path: "/:workspaceId/resources/*",
    element: <ResourcesPage/>,
  },
  {
    path: "/:workspaceId/manage-templates/*",
    element: <TemplatesPage/>,
  },
  {
    path: "/:workspaceId/edit-template/:templateId/*",
    element: <EditTemplatePage/>,
  },
  {
    path: "/:workspaceId/create-directory",
    element: <CreateDirectoryPage/>,
  },
  {
    path: "/:workspaceId/create-document",
    element: <CreateDocumentPage/>,
  },
  {
    path: "/:workspaceId/upload-image",
    element: <UploadImagePage/>,
  },
])

export const App = () =>
  <WorkspaceProvider _apiUrl={config.cmsApi}>
    <Theme theme={ossyTheme}>
      <RouterProvider router={router}/>
    </Theme>
  </WorkspaceProvider>
