// import { useParams } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'

const markdown = `
  # Ossy cms client react

  **Obs!**

  This is an early alpha build **not ready for production**

  ## Getting started

  \`\`\`
  npm install @ossy/cms-client-react
  \`\`\`

  \`\`\`
  // somewhere high up the component tree
  import { WorkspaceProvider } from '@ossy/cms-client-react'

  export const App = () => (
    <WorkspaceProvider workspaceId={<yourWorkspaceId>}>
      {//...}
    </WorkspaceProvider>
  )
  \`\`\`
  \`\`\`
  // get cms data
  import { AsyncSatus, useResources } from '@ossy/cms-client-react'

  export const MyComponent = () => {
    const { status, resources } = useResources(\`/folder/path/in/cms\`)

    return (
      <>
        { status === AsyncSatus.Error && (
          Something went wrong
        )}

        { status === AsyncSatus.Loading && (
          Loading...
        )}

        { status === AsyncSatus.Success && (
          resources
            .filter(resource => resource.type !== 'directory')
            .map(resource => (
              <div key={resource.id}>
                {resource.name}
              </div>
            ))
        )}
      </>
    )
  }
  \`\`\`
`

export const DocumentationPage = () =>
  <ReactMarkdown children={markdown}/>
