import React, { useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuthentication } from '@ossy/cms-client-react'
import {
  useInputValue,
  Button,
  Layout,
  H1,
  Input,
  Switch
} from '@ossy-se/components-react'
import { useDocumentTitle } from 'hooks/index.js'

const FlowStage= {
  EnterEmail: 'EnterEmail',
  Success: 'Success',
  Error: 'Error'
}

export const SignUpPage = () => {
  const navigate = useNavigate()
  const { signUp } = useAuthentication()
  const [flowStage, setFlowStage] = useState(FlowStage.EnterEmail)
  const [email, setEmail] = useInputValue()
  const formRef = useRef()

  useDocumentTitle('Sign up')

  const createAccount = event => {
    event.preventDefault()
    formRef.current.reportValidity() && signUp(email)
      .then(() => setFlowStage(FlowStage.Success))
      .catch(() => setFlowStage(FlowStage.Error))
  }

  return (
    <Layout variant="PerceivedMiddle" className="bg-gradient-orange-blue vh-100">
      <div slot="content" style={{ width: '100vw', maxWidth: '372px' }}>
        <Switch on={flowStage}>

          <Switch.Case match={[FlowStage.EnterEmail]}>
            <H1 className="stack-m">Sign up</H1>
            <form
              ref={formRef}
              onSubmit={createAccount}
              className="d-flex flex-column"
              style={{ marginBottom: '160px', width: '100%' }}
            >
              <Input
                id="registerField"
                className="stack-m"
                type="email"
                placeholder="Email address"
                required
                onChange={setEmail}
              />

              <div className="d-flex justify-end">

                <Button
                  variant="link"
                  id="cancelButton"
                  onClick={() => navigate(-1)}>Cancel
                </Button>

                <Button
                  variant="cta"
                  id="registerButton"
                  type="submit"
                  onClick={createAccount}>Sign up
                </Button>
              </div>
            </form>
          </Switch.Case>

          <Switch.Case match={[FlowStage.Success]}>
            Success - Check your email inbox for a verification email
          </Switch.Case>

          <Switch.Case match={[FlowStage.Error]}>
            Something went wrong - try again in a couple of minutes
          </Switch.Case>

        </Switch>

      </div>
    </Layout>
  )
}
