import React from 'react'
import { Button } from '@ossy-se/components-react'

const MobileMenuItem = ({ icon, ...restProps }) =>
  <Button variant="link" {...restProps} style={{ padding: '8px 16px' }}>
    {icon}
  </Button>

export const MobileMenu = ({
  children,
  ...props
}) =>
  <div {...props} className="bg-color-foreground inset-s shadow-m border-radius-m d-flex justify-around">
    {children}
  </div>

MobileMenu.Item = MobileMenuItem
