import React, { useState } from 'react'
import { cond, equals, ifElse, without, any} from 'ramda'
import { Input2, Select, Button, useInputValue } from '@ossy-se/components-react'
import './editFields.css'

const Options = ({ options, onAddOption, onRemoveOption }) => {
  const [option, setOption, resetOption] = useInputValue('')
  const [error, setError] = useState()

  const addOption = () => {
    if (options.includes(option)) {
      setError('options need to be unique')
    } else {
      onAddOption(option)
      resetOption()
    }
  }

  const updateOption = event => {
    setError()
    setOption(event)
  }

  return (
    <div className="d-inline text-left">
      {
        options.map((option, i) => (
          <div className="d-flex justify-between align-center stack-m" key={option}>
            <span className="inline-m d-inline-block">{option}</span>
            <Button variant="filled" onClick={ () => { onRemoveOption(option, i) } }>remove</Button>
          </div>
        ))
      }
      <div className="d-flex justify-between">
        <Input2
          id="option"
          className="inline-m stack-s"
          placeholder="Option"
          value={option}
          onChange={updateOption}
        />
        <Button variant="link" onClick={addOption}>Add</Button>
      </div>
      <div>{ error }</div>
    </div>
  )
}

export const EditTemplateFields = ({
  fields = [],
  onAddField = () => {},
  onRemoveField = () => {},
  className = '',
  ...restProps
}) => {
  const [name, setName] = useInputValue()
  const [type, setType, resetType] = useInputValue('text')
  const [options, setOptions] = useState([])
  const [error, setError] = useState()

  const resetFieldNameAndType = () => {
    setName()
    resetType()
  }

  const addTextField = () => {
    onAddField({ name, type })
    resetFieldNameAndType()
  }

  const addFieldWithOptions = () => {
    onAddField({ name, type, options })
    resetFieldNameAndType()
    setOptions([])
  }

  const addField = () => {
    if (fields.map(field => field.name).includes(name)) {
      setError('Field names need to be unique')
    } else {
      cond([
        [equals('text'), addTextField],
        [equals('textarea'), addTextField],
        [equals('select'), addFieldWithOptions],
        [equals('radio'), addFieldWithOptions],
        [equals('checkbox'), addFieldWithOptions],
        [equals('image'), addTextField]
      ])(type)
    }
  }

  const removeField = field => () => {
    onRemoveField(field)
  }

  const addOption = option => {
    setOptions([...options, option])
  }

  const removeOption = option => {
    setOptions(without([option], options))
  }

  const updateName = event => {
    setError()
    setName(event)
  }

  return (
    <table className={`template w-100 ${className}`} {...restProps}>
      <thead>
        <tr className="text-left">
          <th>Name</th>
          <th>Type</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {fields.map((field) =>
          <tr key={field.name}>
            <td>{field.name}</td>
            <td>{field.type}</td>
            <td>
              <Button variant="filled" onClick={removeField(field)}>
                remove
              </Button>
            </td>
          </tr>
        )}
        <tr>
          <td>
            <Input2
              id="name"
              type="text"
              placeholder="Name"
              value={name}
              onChange={updateName}
            />
            <div>{ error }</div>
          </td>
          <td>
            <Select name="fieldType" id="fieldType" value={type} onChange={setType}>
              <option value="text">Text</option>
              <option value="textarea">Text Area</option>
              <option value="select">Select</option>
              <option value="radio">Radio</option>
              <option value="checkbox">Checkbox</option>
              <option value="image">Image</option>
            </Select>
          </td>
          <td>
            <Button variant="filled" id="addField" onClick={addField} >
              Add
            </Button>
          </td>
        </tr>
        {
          ifElse(
            any(equals(type)),
            () => (
              <tr>
                <td colSpan="3" style={{ paddingLeft: '24px'}}>
                  <Options options={options} onAddOption={addOption} onRemoveOption={removeOption}/>
                </td>
              </tr>
            ),
            () => <></>
          )(['select', 'checkbox', 'radio'])
        }

      </tbody>
    </table>
  )
}
