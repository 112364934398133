import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useResources } from '@ossy/cms-client-react'
import {
  Input,
  H1,
  Modal,
  Button,
  Layout,
  useInputValue
} from '@ossy-se/components-react'
import { useQueryParams } from 'hooks'

export const CreateDirectoryPage = () => {
  const navigate = useNavigate()
  const { location } = useQueryParams()
  const { workspaceId } = useParams()
  const { createDirectory } = useResources()
  const [directoryName, setDirectoryName] = useInputValue('')
  const [error, setError] = useState()

  const onCreateDirectory = () => {
    setError()
    createDirectory({
      name: directoryName,
      location: location
    })
      .then(() => {
        setDirectoryName()
        navigate(`/${workspaceId}/resources${location}${directoryName}`)
      })
      .catch(err => {
        setError(err.message)
      })
  }

  return (
    <Layout variant="PerceivedMiddle" style={{ height: '100%' }}>
      <div slot="content" style={{ width: '100vw', maxWidth: '400px' }}>
        <H1 className="stack-l">Create a new directory</H1>
        Location: {location}
        <Input
          id="path"
          className="stack-m"
          type="text"
          placeholder="Directory name"
          value={directoryName}
          onChange={setDirectoryName}
          style={{ width: '100%' }}
        />
        <div>{ error }</div>
        <div className="d-flex justify-end">
          <Button
            id="createDirectory"
            variant="cta"
            onClick={onCreateDirectory}
          >Create directory
          </Button>
        </div>
      </div>
    </Layout>
  )
}
